/* LogViewer.css */

/* Block: log-viewer */
.log-viewer {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .log-viewer__title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Element: log-selector */
  .log-viewer__log-selector {
    margin-bottom: 20px;
  }
  
  .log-viewer__log-selector-label {
    font-size: 14px;
    color: #555;
  }
  
  .log-viewer__log-selector-dropdown {
    margin-left: 10px;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Element: log-levels */
  .log-viewer__log-levels {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .log-viewer__log-level {
    display: flex;
    align-items: center;
  }
  
  .log-viewer__log-level input[type="checkbox"] {
    margin-right: 5px;
  }
  
  /* Element: log-content */
  .log-viewer__log-content {
    max-height: 500px;
    overflow-y: auto;
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: "Courier New", Courier, monospace;
    white-space: pre-wrap;
    color: #444;
  }
  
  .log-viewer__log-line {
    margin-bottom: 5px;
  }
  
  /* Element: load-more button */
  .log-viewer__load-more {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .log-viewer__load-more:hover {
    background-color: #218838;
  }
  
  /* Element: error and loading messages */
  .log-viewer__error-message {
    color: red;
    text-align: center;
    font-weight: bold;
  }
  
  .log-viewer__loading-message {
    color: #555;
    text-align: center;
    font-size: 16px;
  }