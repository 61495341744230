/* SystemCheck.module.css */

.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filterContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  .datePicker {
    display: flex;
    flex-direction: column;
  }
  
  .button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .loading {
    text-align: center;
  }
  
  .summaryCards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    flex: 1;
    margin: 0 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .card h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .card p {
    font-size: 1.5em;
    color: #007bff;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tr:hover {
    background-color: #f1f1f1;
  }
  