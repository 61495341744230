.label-section {
  padding: 20px;
  border: 1px solid #ccc;
  height: 80vh;
  overflow-y: auto; /* 允许垂直滚动 */
}

.category-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.category-title {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}

.subcategory-list {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.subcategory-item {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.subcategory-item.selected {
  background-color: #398cf8;
}

.add-category-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="text"] {
  padding: 5px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: rgb(8, 8, 8);
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Modal 样式 */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}
