/* Overall layout and typography */
.list-with-back-button {
  display: flex;
  flex-direction: column;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 20px 0;
}

.back-button {
  padding: 10px 15px;
  cursor: pointer;
  color: #007bff;
  margin-bottom: 15px;
  border-bottom: 2px solid #ddd;
  font-size: 18px;
  font-weight: bold;
}

.list-container {
  max-height: 400px; /* 增加列表高度 */
  overflow-y: auto; /* 垂直滚动条 */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fafafa;
}

.list-item {
  padding: 15px 20px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-radius: 4px;
  margin: 5px 0;
}

.list-item:hover,
.list-item.active {
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button-group {
  display: flex;
  gap: 10px;
}

.action-button,
.fill-button {
  padding: 8px 15px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.action-button {
  background-color: #007bff;
  color: white;
}

.action-button:hover {
  background-color: #0056b3;
}

.fill-button {
  background-color: #dc3545;
  color: white;
}

.fill-button:hover {
  background-color: #c82333;
}

/* Confirm dialog styling */
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 10px;
  max-width: 320px;
  text-align: center;
}

.confirm-dialog p {
  margin-bottom: 20px;
  font-size: 16px;
}

.confirm-dialog button {
  margin-right: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

.confirm-dialog button:first-child {
  background-color: #28a745;
  color: white;
}

.confirm-dialog button:first-child:hover {
  background-color: #218838;
}

.confirm-dialog button:last-child {
  background-color: #6c757d;
  color: white;
}

.confirm-dialog button:last-child:hover {
  background-color: #5a6268;
}

/* Header bar styling */
.header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background-color: #f8f9fa;
  padding: 10px 20px;
  border-radius: 8px;
}

.back-button {
  cursor: pointer;
  font-weight: bold;
  color: #007bff;
  font-size: 18px;
}

.item-count {
  font-size: 16px;
  font-weight: bold;
  color: #343a40;
}

.locate-button,
.jump-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.locate-button:hover,
.jump-button:hover {
  background-color: #0056b3;
}

.jump-input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.jump-to-id {
  display: flex;
  align-items: center;
}

/* Improved scrollable list styling */
.scrollable-list {
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.scrollable-list::-webkit-scrollbar {
  width: 8px;
}

.scrollable-list::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.scrollable-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scrollable-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
