.label-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-section, .right-section {
  width: 48%;
}

.list-container {
  max-height: 200px; /* 限制高度 */
  overflow-y: auto; /* 添加垂直滚动条 */
  border: 1px solid #ddd;
  border-radius: 4px;
}

.list-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.list-item:hover {
  background-color: #f0f0f0;
}

.left-section input,
.left-section button {
  display: block;
  margin: 10px 0;
}

.back-button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  background-color: #ddd;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-top: 10px;
  height: 20px;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  color: white;
}

/* Modal 样式 */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
