.app {
    display: flex;
  }
  
  .content {
    flex: 1;
    margin-left: 80px; /* 当导航栏收缩时 */
    padding: 20px;
    transition: margin-left 0.3s ease;
  }
  
  .navbar.expanded ~ .content {
    margin-left: 220px; /* 当导航栏展开时 */
  }
  
  .login-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #45a049;
  }
  