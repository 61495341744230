.navbar {
    width: 60px;
    height: 100vh;
    background-color: #333;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: width 0.3s ease;
  }
  
  .navbar.expanded {
    width: 200px;
  }
  
  .navbar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .navbar li {
    margin: 20px 0;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    padding: 10px;
    display: block;
    transition: padding-left 0.3s ease;
  }
  
  .navbar.expanded a {
    padding-left: 20px;
  }
  
  .navbar a:hover {
    background-color: #575757;
  }
  