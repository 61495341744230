.plan {
  padding: 20px;
}

.plan h2 {
  font-size: 24px;
  margin-bottom: 16px;
  text-align: center;
}

.add-plan-button {
  display: block;
  margin: 0 auto 20px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.add-plan-button:hover {
  background-color: #45a049;
}

.new-plan-form {
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.new-plan-form input,
.new-plan-form textarea {
  padding: 8px;
  font-size: 14px;
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.plan-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.plan-table th,
.plan-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  position: relative;
  vertical-align: top;
}

.plan-table th {
  background-color: #f2f2f2;
}

.plan-item input,
.plan-item textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: none;
  background-color: transparent;
}

.plan-item input.editable,
.plan-item textarea.editable {
  background-color: #f9f9f9;
}

.plan-table th select {
  margin-top: 5px;
  padding: 5px;
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
}

.markdown-content {
  text-align: left;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin: 10px 0;
  font-weight: bold;
}

.markdown-content p {
  margin: 10px 0;
}

.markdown-content ul,
.markdown-content ol {
  margin: 10px 0 10px 20px;
  text-align: left;
}

.markdown-content li {
  margin-bottom: 5px;
}

.markdown-content code {
  background-color: #f2f2f2;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
}

.markdown-content pre {
  background-color: #f2f2f2;
  padding: 10px;
  overflow-x: auto;
  border-radius: 4px;
}

.project-group {
  margin-bottom: 40px;
}

.details-textarea {
  min-height: 80px;
  resize: vertical;
}
