/* Dashboard.css */

.dashboard {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.dashboard__title {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard__main-content {
  display: flex;
  gap: 20px;
}

/* 右侧面板 */
.dashboard__right-panel {
  flex: 1;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.dashboard__section-title {
  margin-bottom: 10px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.queued-tasks-list {
  max-height: 400px;
  overflow-y: auto;
}

.queued-task-card {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* 左侧面板 */
.dashboard__left-panel {
  flex: 2;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.stations-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.station-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.station-card h3 {
  margin-top: 0;
}

.instances-list {
  margin-top: 10px;
  padding-left: 15px;
  border-left: 2px solid #ddd;
}

.instance-card {
  background-color: #fafafa;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.instance-card p {
  margin: 5px 0;
}

button {
  padding: 6px 12px;
  margin-top: 10px;
  background-color: #1976d2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #1565c0;
}

/* 模态框样式 */
.dashboard_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  z-index: 1000;           /* 确保模态框在最上层 */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;        /* 设置最大宽度，防止过大 */
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  margin-bottom: 10px;
}

.modal-content input,
.modal-content select {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
}

.modal-content button {
  align-self: flex-end;
  background-color: #4caf50;
}

.modal-content button:hover {
  background-color: #45a049;
}
