/* src/components/AdminManager.css */

.admin-manager {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
}

.admin-info {
  display: flex;
  flex-direction: column;
}

.admin-info div {
  margin-bottom: 5px;
}

.admin-actions {
  margin-top: 10px;
}

.edit-admin-button,
.remove-admin-button,
.add-admin-button {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-admin-button {
  background-color: #4CAF50;
  color: white;
}

.edit-admin-button:hover {
  background-color: #45a049;
}

.remove-admin-button {
  background-color: #f44336;
  color: white;
}

.remove-admin-button:hover {
  background-color: #da190b;
}

.add-admin-button {
  background-color: #008CBA;
  color: white;
}

.add-admin-button:hover {
  background-color: #007B9E;
}

.loading {
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}

/* 模态框样式（与 OrganizationManager 相同，可共用） */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001; /* 确保在组织模态框之上 */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  position: relative;
}

.admin-form label {
  display: block;
  margin-bottom: 10px;
}

.admin-form input {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.submit-button,
.cancel-button {
  padding: 8px 16px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
}

.submit-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.cancel-button:hover {
  background-color: #da190b;
}
