/* Backstage.css */

.tab-menu {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
  }
  
  .tab-menu button {
    background-color: #f0f0f0;
    border: none;
    width: 5%;
    border-radius: 0px;
    border: 1px solid rgba(99, 99, 99, 0.1);
    padding: 10px 20px;
    margin: 0 0px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .tab-menu button:hover {
    background-color: #dcdcdc;
  }
  
  .tab-menu button.active {
    background-color: #bebebe;
    color: #ffffff;
  }
  
  .tab-content {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  