/* MarkdownEditor.css */

.react-mde {
    display: flex;
    flex-direction: column;
    height: 100%; /* 占据容器的全部高度 */
  }
  
  .react-mde .mde-tabs {
    display: flex;
    justify-content: flex-start;
    background-color: #f7f7f7;
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;
  }
  
  .react-mde .mde-content {
    flex: 1;
    display: flex;
    height: calc(100% - 40px); /* 减去选项卡高度 */
    overflow: hidden;
  }
  
  .react-mde .react-mde-tabbed-layout {
    height: calc(100% - 40px);
  }
  
  .react-mde .mde-textarea-wrapper,
  .react-mde .mde-preview {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 1rem;
    background-color: #fff;
  }
  
  .react-mde .mde-textarea-wrapper {
    margin-right: 0.5rem;
  }
  
  .react-mde .mde-textarea {
    height: 100%; /* 占据父容器高度 */
    min-height: 400px; /* 确保高度足够大 */
  }
  
  .react-mde .mde-preview {
    height: 100%; /* 与编辑框高度一致 */
  }
  
  button {
    display: block;
    margin: 1rem 0 0 auto;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #000000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  