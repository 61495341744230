/* src/components/OrganizationManager.css */

.organization-manager {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.top-bar {
  margin-bottom: 20px;
}

.create-button {
  padding: 10px 20px;
  background-color: #008CBA;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-button:hover {
  background-color: #007B9E;
}

.notification {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
}

.notification.info {
  background-color: #d1ecf1;
  color: #0c5460;
}

.loading {
  text-align: center;
  font-size: 18px;
}

.organization-table {
  width: 100%;
  border-collapse: collapse;
}

.organization-table th,
.organization-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.organization-table th {
  background-color: #f2f2f2;
}

.organization-table tr.deleted {
  background-color: #f9d6d5;
}

.organization-table tr:hover {
  background-color: #f1f1f1;
}

.expand-button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.edit-button,
.delete-button {
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-button {
  background-color: #4CAF50;
  color: white;
}

.edit-button:hover {
  background-color: #45a049;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #da190b;
}

.no-data {
  text-align: center;
  padding: 20px 0;
}

.pagination-buttons {
  margin-top: 20px;
  text-align: center;
}

.pagination-buttons button {
  padding: 8px 16px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-buttons button:not(:disabled) {
  background-color: #008CBA;
  color: white;
}

.pagination-buttons button:not(:disabled):hover {
  background-color: #007B9E;
}

/* 模态框样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  position: relative;
}

.organization-form label {
  display: block;
  margin-bottom: 10px;
}

.organization-form input {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.submit-button,
.cancel-button {
  padding: 8px 16px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
}

.submit-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.cancel-button:hover {
  background-color: #da190b;
}

/* 管理员详细信息行样式 */
.admin-row td {
  background-color: #f9f9f9;
}
