/* ImageAssets.css */
.image-assets-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
  }
  
  .title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .request-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .request-id, .prompt, .username {
    font-size: 1.1rem;
    margin: 10px 0;
  }
  
  .image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
  
  .image-card {
    position: relative;
    width: 220px;
    text-align: center;
  }
  
  .image-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .image-thumbnail:hover {
    transform: scale(1.05);
  }
  
  .seed {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #555;
  }
  
  .pagination {
    text-align: center;
    margin-top: 30px;
  }
  
  .pagination-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
    transition: background-color 0.3s;
  }
  
  .pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .page-number {
    font-size: 1.2rem;
    display: inline-block;
    margin: 0 10px;
  }
  
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lightbox-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
  }
  
  .lightbox-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .close-lightbox {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    color: #333;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .close-lightbox:hover {
    background-color: #ff4d4d;
  }
  